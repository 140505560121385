@import '../styles/vendors/material-shadows';
@import '../styles/partials/variables';
@import '../styles/partials/mixins';

.LoginLayout {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 400px;
  background-image: url('/img/login-bg.jpg');

  .l-block {
    background: #fff;
    border-radius: 2px;
    max-width: 380px;
    width: 100%;
    vertical-align: middle;
    position: relative;
    display: none;
    text-align: left;
    @include z-depth-2dp;

    &.toggled {
      @include animated(fadeInUp, 300ms);
      z-index: 10;
      display: inline-block;
    }

    //@media (max-width: $screen-xs-max) {
    //  max-width: 340px;
    //}
  }

  .lb-header {
    padding: 20px 30px 20px;
    border-radius: 1px 1px 0 0;
    background-color: $brand-primary;

    .logo {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      img {
        display: block;
        height: 36px;
      }

      h1 {
        margin: 0 0 0 10px;
        color: white;
        font-size: 36px;
        font-family: 'Lobster', sans-serif;
      }
    }

    i,
    .avatar-img {
      display: block;
      margin-bottom: 10px;
    }

    .avatar-img {
      border: 2px solid #fff;
    }

    i {
      font-size: 40px;
    }
  }

  .links {
    border-top: 1px solid rgba($m-gray, 0.3);
    text-align: center;
    padding: 5px 0 20px;
    margin: 0 30px;

    a {
      color: $m-gray;
      cursor: pointer;
      font-size: 12px;

      &:hover {
        color: $brand-primary;
      }
    }
  }
}
