@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

/*
 * Material Colors
 */
$m-white:                  #ffffff;
$m-black:                  #000000;
$m-blue:                   #2196F3;
$m-red:                    #F44336;
$m-purple:                 #9C27B0;
$m-deeppurple:             #673AB7;
$m-lightblue:              #03A9F4;
$m-cyan:                   #00BCD4;
$m-teal:                   #009688;
$m-green:                  #4CAF50;
$m-lightgreen:             #8BC34A;
$m-lime:                   #CDDC39;
$m-yellow:                 #FFEB3B;
$m-amber:                  #FFC107;
$m-orange:                 #FF9800;
$m-deeporange:             #FF5722;
$m-gray:                   #9E9E9E;
$m-bluegray:               #607D8B;
$m-indigo:                 #3F51B5;
$m-pink:                   #E91E63;
$m-brown:                  #795548;

$m-dark:                   #1f292f;
$m-card:                   #263238;
$overlay-white:            rgba(255,255,255,0.12);
$overlay-black:            rgba(0, 0, 0, 0.04);

$brand-primary:             $m-teal;

/*
 * Font Icon Family
 */
$font-icon-md:              'Material-Design-Iconic-Font';
$icon-font-path:            '/fonts';
$zmdi-font-path:            "/fonts";

/* --------------------------------------------------------
    Container
-----------------------------------------------------------*/
$container-tablet:                      100%;
$container-desktop:                     100%;
$container-large-desktop:               100%;


/* Typography + Scaffolding + Links */
$body-bg:                   #edecec;
$text-color:                #5E5E5E;
$font-family-sans-serif:    noto-sans;
$font-size-base:            13px;
$link-hover-decoration:     none;
$headings-color:			#000000;
$sidebar-link-color:        #4C4C4C;

/* Border Radius */
$border-radius-base:        2px;
$border-radius-large:       4px;
$border-radius-small:       2px;

/* Tabs */
$nav-tabs-border-color:                     #fff;
$nav-tabs-active-link-hover-border-color:   #fff;
$nav-tabs-active-link-hover-bg:             transparent;

/* Form */
$input-border:              #e0e0e0;
$input-color:               #000000;
$input-border-radius:       0;
$input-border-radius-large: 0px;
$input-height-large:        40px;
$input-height-base:         35px;
$input-height-small:        30px;
$input-border-focus:        #b4b4b4;

/* Table */
$table-bg:                      #fff;
$table-border-color:            #f0f0f0;
$table-cell-padding:            15px;
$table-condensed-cell-padding:  10px;
$table-bg-accent:               #f4f4f4;
$table-bg-active:               #FFFCBE;

/*
 * Input Group
 */
$input-group-addon-bg: transparent;
$input-group-addon-border-color: transparent;

/*
 * Pagination
 */
$pagination-bg:                 #E2E2E2;
$pagination-border:             #fff;
$pagination-color:              #7E7E7E;
$pagination-active-bg:          $m-cyan;
$pagination-active-border:      $pagination-border;
$pagination-disabled-bg:        #E2E2E2;
$pagination-disabled-border:    $pagination-border;
$pagination-hover-color:        #333;
$pagination-hover-bg:           #d7d7d7;
$pagination-hover-border:       $pagination-border;
$pager-border-radius:           5px;

/*
 * Popover
 */
$popover-fallback-border-color: transparent;
$popover-border-color:          transparent;

/*
 * Dropdown
 */
$dropdown-fallback-border:      transparent;
$dropdown-border:               transparent;
$dropdown-divider-bg:           '';
$dropdown-link-hover-bg:        rgba(0,0,0,0.075);
$dropdown-link-color:           $text-color;
$dropdown-link-hover-color:     #333;
$dropdown-link-disabled-color:  #e4e4e4;
$dropdown-divider-bg:           rgba(0,0,0,0.08);
$dropdown-link-active-color:    #333;
$dropdown-link-active-bg:       rgba(0, 0, 0, 0.075);
$zindex-dropdown:               9;
$dropdown-shadow:               0 2px 10px rgba(0, 0, 0, 0.2);

/*
 * Thumbnail
 */
$thumbnail-bg:                  #fff;

/*
 * Alerts
 */
$alert-success-border:                  transparent;
$alert-info-border:                     transparent;
$alert-warning-border:		 	        transparent;
$alert-danger-border:		 	        transparent;
$alert-inverse-border:		 	        transparent;

$alert-success-bg:                      $m-green;
$alert-info-bg:		 		            $m-blue;
$alert-warning-bg:                      $m-amber;
$alert-danger-bg:                       $m-red;
$alert-inverse-bg:                      #333;

$alert-success-text:                    #fff;
$alert-info-text:                       #fff;
$alert-warning-text:                    #fff;
$alert-danger-text:                     #fff;
$alert-inverse-text:                    #fff;

/*
 * Form Validations
 */
$state-success-text:                    lighten($m-green, 8%);
$state-warning-text:                    lighten($m-orange, 8%);
$state-danger-text:                     lighten($m-red, 8%);

$state-success-bg:                      lighten($m-green, 8%);
$state-warning-bg:                      lighten($m-orange, 8%);
$state-danger-bg:                       lighten($m-red, 8%);



/*
 * Buttons
 */
$border-radius-base:            2px;
$border-radius-large:           2px;
$border-radius-small:           2px;
$btn-font-weight:				400;

/*
 * Thumbnail
 */
$thumbnail-border:				#EDEDED;
$thumbnail-padding:				3px;

/*
 * Carousel
 */
$carousel-caption-color:        #fff;

/*
 * Modal
 */
$modal-content-fallback-border-color:	transparent;
$modal-content-border-color:			transparent;
$modal-backdrop-bg:						#000;
$modal-header-border-color:				transparent;
$modal-title-line-height:				transparent;
$modal-footer-border-color:				transparent;
$zindex-modal-background: 				11;

/*
 * Tooltips
 */
$tooltip-bg:                #737373;
$tooltip-opacity:           1;

/*
 * Popover
 */
$zindex-popover:                9;
$popover-title-bg:              #fff;
$popover-border-color:          #fff;
$popover-fallback-border-color: #fff;

/*
 * Jumbotron
 */
$jumbotron-bg:              #F7F7F7;

/* --------------------------------------------------------
    List Group
-----------------------------------------------------------*/
$list-group-bg:                         transparent;
$list-group-border:                     #f4f4f4;
$list-group-active-color:               #000;
$list-group-active-bg:                  #f5f5f5;
$list-group-active-border:              $list-group-border;
$list-group-disabled-color:             #B5B4B4;
$list-group-disabled-bg:                #fff;
$list-group-disabled-text-color:        #B5B4B4;

/*
 * Badges
 */
$badge-color:               #fff;
$badge-bg:                  $brand-primary;
$badge-border-radius:       2px;
$badge-font-weight:         400;
$badge-active-color:        #fff;
$badge-active-bg:           $brand-primary;

/* --------------------------------------------------------
    Header
-----------------------------------------------------------*/
$header-bg:                 $m-teal;
$header-height:             265px;

/* Bootstrap Branding */
$brand-primary:             $m-blue;
$brand-success:             $m-green;
$brand-info:                $m-cyan;
$brand-warning:             $m-orange;
$brand-danger:              $m-red;
$app-gray:                  #F7F7F7;


/*
 * Colors
 */
$ace:                      #F7F7F7;

/*
 * Blocks
 */
$sidebar-left-width: 270px;


/*
 * Misc
 */
$card-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
$sidebar-shadow: 0px 0 30px rgba(0, 0, 0, 0.3);

/* --------------------------------------------------------
    Custom Media Query widths
-----------------------------------------------------------*/
$screen-mobile-min: 1280px;
$screen-mobile-max: 1279px;


/* --------------------------------------------------------
    Pre
-----------------------------------------------------------*/
$pre-bg:                      #333;
$pre-color:                   #ccc;
$pre-border-color:            #333;
$pre-scrollable-max-height:   340px;
