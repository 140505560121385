*, button, input, i, a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:active,
*:hover {
  outline: none !important;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
}

html {
  overflow-x: hidden;
  -ms-overflow-style: none;
}

body {
  background-color: #edecec;
}

audio, video {
  outline: none;
}

p {
  margin-bottom: 20px;
}

blockquote {
  color: #AEAEAE;
}

pre {
  padding: 25px;
}

a:hover {
  text-decoration: none;
}

.container {
  padding: 0;

  &.c-boxed {
    max-width: 1200px;
  }
}