body {
  font-size: 14px;
}

/*----------------------------------
    Avatars
-----------------------------------*/
.avatar-img,
.avatar-char {
  border-radius: 50%;

  &:not(.a-lg) {
    width: 38px;
    height: 38px;
  }

  &.a-lg {
    width: 50px;
    height: 50px;
  }
}

.avatar-char {
  color: #fff;
  line-height: 38px;
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;

  &.ac-light {
    color: #717171;
  }
}

.ac-check {
  position: relative;
  cursor: pointer;

  .acc-helper,
  .acc-check {
    position: absolute;
    height: 100%;
    width:100%;
    top: 0;
    left: 0;
  }

  .acc-check {
    z-index: 1;
    margin: 0;
    opacity: 0;

    &:checked + span {
      background-color: #e1e1e1;
      font-size: 0;

      &:before {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        border: 0;
        background-color: transparent;
        content: '\f26b';
        font-family: $font-icon-md;
        font-size: 23px;
        color: #333;
      }
    }
  }

  .acc-helper {
    border-radius: 50%;
    transition: all;
    transition-duration: 100ms;
  }

  &:hover {
    .acc-helper {
      background-color: #e1e1e1;

      &:before {
        content: '';
        position: absolute;
        width: 18px;
        height: 18px;
        border-radius: 2px;
        border: 2px solid #7a7a7a;
        background-color: #fff;
        top: 10px;
        left: 10px;
      }
    }
  }
}

/*----------------------------------
    View More
-----------------------------------*/
.view-more {
  text-align: center;
  padding: 10px 0;
  color: #AEAEAE;
  font-size: 12px;
  margin-top: 10px;
  border-bottom: 0;

  &:hover {
    color: #929292;
  }

  i {
    font-size: 15px;
    width: 29px;
    height: 29px;
    border-radius: 50%;
    background: #EFEFEF;
    text-align: center;
    line-height: 30px;
    margin-right: 5px;
    color: #9E9E9E;
  }
}


/*
 * Content Header
 * Used for Heading outside the Cards.
 */
.c-header {
  padding: 0 18px;
  //@media screen and (min-width: $screen-sm-min) {
  //  padding: 0 32px;
  //}
  //
  //@media screen and (max-width: $screen-sm-max) {
  //  padding: 0 18px;
  //}

  margin-bottom: 20px;
  position: relative;

  & > h2 {
    font-size: 18px;
    color: #fff;
    margin: 0;
    font-weight: 400;
    text-transform: uppercase;

    & > small {
      display: block;
      text-transform: none;
      margin-top: 8px;
      margin-bottom: 20px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 140%;
      font-size: 13px;
    }
  }

  .actions {
    position: absolute;
    right: 15px;
    top: -5px;
    z-index: 4;
  }
}

/*
 * Header Actions
 */

.actions {
  list-style: none;
  padding: 0;
  z-index: 3;
  margin: 0;

  & > li {
    display: inline-block;
    vertical-align: baseline;

    & > a,
    & > button {
      display: inline-block;
      padding-top: 5px;
      font-size: 14px;
      line-height: 20px;

      & > i {
        margin-top: 0;
        color: #ffffff;
        font-size: 20px !important;
      }

      &.-with-text {
        color: #ffffff;
        display: inline;
        border-radius: 3px;
      }

      &.btn-icon {
        width: 32px;
        height: 32px;
      }

      &:hover:not([disabled]),
      &:focus:not([disabled]),
      &:active:not([disabled]) {
        background-color: rgba(0,0,0,0.2);
      }
    }

    & > a,
    & > button,
    & > a > i,
    & > button > i {
      transition: all;
      transition-duration: 400ms;
    }
  }

  &.a-alt > li > a > i {
    color: #fff;
  }
}

/*
 * Collapse Menu Icons
 */
.menu-collapse:before,
.mc-wrap,
.mcw-line {
  transition: all;
  transition-duration: 300ms;
}

.menu-collapse {
  cursor: pointer;
  position: relative;
  width: 55px;
  height: 35px;
  padding: 12px 0 0 8px;

  &:before {
    content: "";
    position: absolute;
    top: -5px;
    left: -6px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.12);
    transform: scale(0);
    z-index: 0;
  }

  &.toggled {
    .mc-wrap {
      transform: rotate(180deg);

      .mcw-line {
        &.top {
          width: 12px;
          transform: translateX(8px) translateY(1px) rotate(45deg);
          -webkit-transform: translateX(8px) translateY(1px) rotate(45deg);
        }

        &.bottom {
          width: 12px;
          transform: translateX(8px) translateY(-1px) rotate(-45deg);
          -webkit-transform: translateX(8px) translateY(-1px) rotate(-45deg);
        }
      }
    }

    &:before {
      transform: scale(1);
    }
  }

}

.mc-wrap {
  width: 18px;
  height: 12px;

  .mcw-line{
    width: 18px;
    height: 2px;
    background-color: white;

    &.center {
      margin: 3px 0;
    }
  }
}

/*
 * Load More
 */
.load-more {
  text-align: center;
  margin-top: 30px;

  a {
    padding: 8px 15px 6px;
    display: inline-block;
    background-color: $m-red;
    color: #FFF;
    border-radius: 2px;
    white-space: nowrap;

    i {
      font-size: 20px;
      vertical-align: middle;
      position: relative;
      margin-top: -2px;
    }

    &:hover {
      background-color: darken($m-red, 10%);
    }
  }
}

/*----------------------------------------
    Action Header
-----------------------------------------*/
.action-header {
  padding: 25px 30px;
  line-height: 100%;
  font-weight: 500;
  color: #fff;
  margin-bottom: 10px;
  font-size: 16px;
  position: relative;
  z-index: 1;
  background-color: rgba(#f7f7f7, 0.3);
  min-height: 66px;

  .actions {
    position: absolute;
    top: 18px;
    right: 17px;
    z-index: 10;
  }

  .btn-secondary:not(:disabled):not(.disabled):active {
    background-color: transparent;
  }

  @media (max-width: $screen-mobile-max) {
    margin-bottom: 0;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.ah-search {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 11;
  background: #fff;
  display: none;
}

.ahs-input {
  border: 0;
  padding: 0 26px 0 55px;
  height: 100%;
  font-size: 15px;
  width: 100%;
  font-weight: 100;
  background: #fff;
  border-bottom: 1px solid #EEE;
  line-height: 60px;
}

.ah-search-close {
  font-style: normal;
  position: absolute;
  top: 23px;
  left: 22px;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}


/*----------------------------------
    Body overflow hidden
-----------------------------------*/
body.o-hidden {
  overflow: hidden;
  height: 100vh;
}