.dropdown-menu {
  box-shadow: $dropdown-shadow;
  @include animated(fadeIn, 300ms);
  padding: 10px 0;
  border-radius: 3px;
  margin: 0;
  border: 0;

  &[x-placement^="bottom"] {
    transform: none !important;
    top: -10px !important;
  }

  & > a {
    font-size: 16px;
    background: none;
    cursor: pointer;
    border: none;
    padding: 10px 20px;
    transition: background-color;
    transition-duration: 300ms;

    &.active,
    &:hover,
    &:focus {
      background-color: rgba(0, 0, 0, 0.075);
      color: #333;
    }

    &.active {
      &::after {
        display: inline-block;
        position: absolute;
        right: 20px;
        font: normal normal normal 18px/1 "Material-Design-Iconic-Font";
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: '\f26b';
      }
    }
  }

  &.dm-icon {
    & > li > a > .zmdi {
      line-height: 100%;
      vertical-align: top;
      font-size: 18px;
      width: 28px;
    }
  }
}

.dm-dark {
  & > li {
    & > button,
    & > button:hover {
      color: #fff;
    }
  }
}

.dropdown-header {
  padding: 10px 16px 5px;
  color: #B9B9B9;
  text-transform: uppercase;
}

.dropdown.show {
  .dropdown-menu {
    display: block;
  }
}