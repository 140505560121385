.sweet-alert {
  border-radius: 2px;
  padding: 10px 30px;

  .sa-icon.sa-warning {
    @extend .zmdi;
    @extend .zmdi-alert-octagon;
    border: none;
    font-size: 80px;
    text-align: center;
    vertical-align: middle;

    > * {
      display: none;
    }
  }

  h2 {
    font-size: 16px;
    font-weight: 400;
    position: relative;
    z-index: 1;
  }

  .lead {
    font-size: 13px;
  }

  button {
    padding: 6px 12px;
    font-size: 13px;
    margin: 10px 2px 20px;
    box-shadow: none !important;
  }
}

.sweet-alert.announcement {
  border-radius: 5px;
  padding: 10px 50px;
  @include z-depth-6dp;

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .lead {
    font-size: 16px;
    margin-bottom: 5px;
    line-height: 1.5;
  }
}