@import '../../styles/partials/variables';

.ShoppingList {
  .card-body {
    padding: 0;
  }

  table.items-list {
    margin-bottom: 0;

    td {
      font-size: 16px;
      border-top: 1px solid #f0f0f0;

      &.quantity {
        font-weight: bold;
        color: $brand-primary;
        text-align: right;
        padding-right: 5px;
      }

      &.name {
        width: 80%;
        padding-left: 5px;

        &::first-letter {
          text-transform: uppercase;
        }
      }
    }

    tr {
      cursor: pointer;

      &:hover {
        background-color: $overlay-black;
      }

      &.crossed {
        opacity: 0.4;
        background-color: $overlay-black;

        .name {
          text-decoration: line-through;
        }

        .actions {
          button {
            visibility: hidden;
          }
        }
      }
    }
  }

  td.actions {
    padding-top: 9px !important;
    padding-bottom: 8px !important;
    text-align: right;

    button {
      width: 32px;
      height: 32px;
      line-height: inherit;
      box-shadow: none !important;
      color: gray;
    }
  }

  @media (max-width: 600px) {
    .c-header {
      padding-left: 10px;
      padding-right: 10px;

      &.editing {
        h2 {
          display: none;
        }

        .AddItem {
          width: 100%;
          position: relative;
          right: 0;
        }

        margin-bottom: 9px;
      }
    }
  }

  .clear-button-container {
    padding-bottom: 30px;
  }
}
