@import '../../node_modules/bootstrap/scss/mixins';
@import '../styles/partials/variables';

.MainLayout {
  background-color: #edecec;

  &::before {
    content: '';
    height: 265px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: $brand-primary;
  }

  #main {
    display: flex;
    padding: 0 15px;

    @include media-breakpoint-up(xl) {
      padding-left: 0;
    }

    @include media-breakpoint-up(md) {
      padding: 0 40px;
    }
  }

  .main {
    flex: 1 1 auto;
  }

  .sidebar {
    width: $sidebar-left-width;
    flex: 0 0 auto;
    margin-top: 106px;
    margin-left: -40px;
    display: none;

    /*----------------------------------
      Sidebar menu
    -----------------------------------*/
    ul.menu {
      list-style: none;
      padding-left: 0;
      margin: 0;

      a {
        color: $sidebar-link-color;
      }

      & > li {
        & > a {
          cursor: pointer;
          padding: 16px 25px;
          display: block;
          position: relative;
          font-weight: 700;

          &:hover,
          &:focus {
            background-color: #F3F3F3;
          }

          & > i {
            position: absolute;
            left: 35px;
            font-size: 22px;
            top: 2px;
            width: 25px;
            text-align: center;
            padding: 13px 0;
          }
        }
      }
    }

    @include media-breakpoint-up(xl) {
      display: block;
    }
  }

  &.cookmode {
    &::before {
      height: 130px;
    }

    @include media-breakpoint-up(xs) {
      #main {
        padding-left: 40px;
      }
    }

    .Header {
      display: none;
    }

    .c-header {
      margin-top: 25px;

      .actions {
        display: none;
      }
    }

    .sidebar {
      display: none;
    }
  }
}