label {
  font-weight: bold;
  text-transform: uppercase;
}

// Remove `green` validated state for form controls
.was-validated .form-control:valid, .form-control.is-valid {
  background: none;
  border-color: $input-border-color;
  padding: $input-padding-y $input-padding-x;

  &:focus {
    border-color: $input-focus-border-color;
    box-shadow: $input-focus-box-shadow;
  }
}

///*
// * Reset Focus and Active shadows
// */
//input:active,
//input:focus {
//  outline: 0;
//  box-shadow: none !important;
//}
//
//.form-control {
//  box-shadow: none !important;
//  resize: none;
//  font-size: 16px;
//
//  &:active,
//  &:focus {
//    box-shadow: none;
//  }
//
//  &:not(.fc-alt) {
//    border-left: 0;
//    border-right: 0;
//    border-top: 0;
//    -webkit-appearance: none;
//    -moz-appearance: none;
//    appearance: none;
//    padding: 0;
//
//    &.auto-size {
//      padding-top: 6px;
//    }
//  }
//}
//
//.form-group {
//  margin-bottom: 25px;
//}
//
///*
// * Checkbox and Radio
// */
//.input-helper:before,
//.input-helper:after,
//.checkbox label:before,
//.radio label:before,
//.radio-inline:before,
//.checkbox-inline:before {
//  transition: all;
//  transition-duration: 250ms;
//}
//
//.checkbox label,
//.radio label,
//.radio-inline,
//.checkbox-inline {
//  padding-left: 30px;
//  position: relative;
//
//  &:before {
//    content: '';
//    width: 47px;
//    height: 47px;
//    border-radius: 50%;
//    background-color: rgba(0,0,0,0.04);
//    position: absolute;
//    left: -15px;
//    top: -15px;
//    opacity: 0;
//    transform: scale(0);
//  }
//
//  &:active:before {
//    opacity: 1;
//    transform: scale(1);
//  }
//}
//
//.checkbox, .radio {
//  label {
//    display: block;
//  }
//
//  input {
//    top: 0;
//    left: 0;
//    margin-left: 0 !important;
//    z-index: 1;
//    cursor: pointer;
//    opacity: 0;
//    margin-top: 0;
//
//    &:checked + .input-helper {
//      &:before {
//        border-color: $brand-primary;
//      }
//    }
//  }
//
//  .input-helper {
//    &:before, &:after {
//      position: absolute;
//      content: "";
//    }
//
//    &:before {
//      left: 0;
//      border: 2px solid #7a7a7a;
//    }
//  }
//
//  &.disabled {
//    opacity: 0.6;
//  }
//}
//
//.checkbox {
//  input {
//    width: 17px;
//    height: 17px;
//
//    &:checked + .input-helper {
//      &:before {
//        background-color: $brand-primary;
//      }
//
//      &:after {
//        opacity: 1;
//        transform: scale(1);
//      }
//    }
//  }
//
//  .input-helper {
//    &:before {
//      top: 0;
//      width: 17px;
//      height: 17px;
//      border-radius: 2px;
//    }
//
//    &:after {
//      opacity: 0;
//      transform: scale(0);
//      content: '\f26b';
//      font-family: $font-icon-md;
//      position: absolute;
//      font-size: 13px;
//      left: 2px;
//      top: 0;
//      color: #fff;
//      font-weight: bold;
//    }
//  }
//}
//
//.radio {
//  input {
//    width: 19px;
//    height: 19px;
//
//    &:checked + .input-helper {
//      &:after {
//        transform: scale(1);
//      }
//    }
//  }
//
//  .input-helper {
//    &:before {
//      top: -1px;
//      width: 19px;
//      height: 19px;
//      border-radius: 50%;
//    }
//
//    &:after {
//      width: 9px;
//      height: 9px;
//      background: $m-teal;
//      border-radius: 50%;
//      top: 4px;
//      left: 5px;
//      transform: scale(0);
//    }
//  }
//}
//
//.checkbox-inline,
//.radio-inline {
//  vertical-align: top;
//  margin-top: 0;
//  padding-left: 25px;
//}

/*
 * Select
 */
//html:not(.ie9) {
//  .select {
//    position: relative;
//
//    &:before {
//      position: absolute;
//      top: -1px;
//      content: "";
//      height: calc(100% - 1px);
//      width: 30px;
//      background-color: #FFF;
//      background-position: right calc(100% - 7px);
//      background-repeat: no-repeat;
//      //@include img-retina('../img/select.png', '../img/select$2x.png', 12px, 12px);
//      pointer-events: none;
//      z-index: 5;
//    }
//
//    &:not(.fg-line):before {
//      right: 0;
//    }
//
//    &.fg-line:before {
//      right: 10px;
//    }
//  }
//}


/*
 * Input Group Addon
 */

//.input-group .input-group-addon {
//  font-size: 18px;
//  padding: 0;
//  min-width: 45px;
//
//  & > .zmdi {
//    position: relative;
//    top: 3px;
//  }
//}
//
//.input-group-lg .input-group-addon {
//  font-size: 20px;
//}
//
//.input-group-sm .input-group-addon {
//  font-size: 16px;
//}



/*
 * Input Feilds
 */
//.fg-line {
//  position: relative;
//  vertical-align: top;
//
//  &:not(.form-group) {
//    display: inline-block;
//    width: 100%;
//  }
//
//  .form-control {
//    &:disabled {
//      color: #9d9d9d;
//      background: transparent;
//    }
//  }
//
//  &:not(.disabled):after,
//  &:not(.readonly):after {
//    position: absolute;
//    z-index: 3;
//    bottom: 0;
//    left: 0;
//    height: 2px;
//    width: 100%;
//    content: "";
//    transform: scale(0);
//    transition: all;
//    transition-duration: 300ms;
//  }
//
//  &:not([class*=has-]):after {
//    background: $m-blue;
//  }
//
//  &.readonly .form-control {
//    color: #9d9d9d;
//    background: transparent;
//  }
//
//  &.fg-toggled {
//    &:after {
//      transform: scale(1);
//    }
//  }
//}
//
//.fg-float  {
//  margin-top: 2px;
//  position: relative;
//
//  .form-control {
//    position: relative;
//    background: transparent;
//    z-index: 1;
//
//    ::placeholder {
//      color: white;
//    }
//  }
//
//  .fg-label {
//    transition: all;
//    transition-duration: 200ms;
//    position: absolute;
//    top: 5px;
//    font-weight: 400;
//    color: #959595;
//    pointer-events: none;
//    z-index: 0;
//    left: 0;
//    white-space: nowrap;
//  }
//
//  .fg-toggled .fg-label {
//    top: -20px;
//    font-size: 11px;
//  }
//}

.control-label {
  font-weight: normal;
}

.help-block {
  font-size: 14px;
}

[hidden].help-block {
  display: none;
}

/*
 * Toggle Switch
 */
//@mixin ts-color($color) {
//  input {
//    &:not(:disabled) {
//      &:checked {
//        & + .ts-helper {
//          background: fade($color, 50%);
//
//          &:before {
//            background: $color;
//          }
//
//          &:active {
//            &:before {
//              box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px fade($color, 20%);
//            }
//          }
//        }
//      }
//    }
//  }
//}

//.toggle-switch {
//  display: inline-block;
//  vertical-align: top;
//  @include user-select(none);
//
//  .ts-label {
//    display: inline-block;
//    margin: 0 20px 0 0;
//    vertical-align: top;
//    -webkit-transition: color 0.56s cubic-bezier(0.4, 0, 0.2, 1);
//    transition: color 0.56s cubic-bezier(0.4, 0, 0.2, 1);
//  }
//
//  .ts-helper {
//    display: inline-block;
//    position: relative;
//    width: 40px;
//    height: 16px;
//    border-radius: 8px;
//    background: rgba(0,0,0,0.26);
//    -webkit-transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
//    transition: background 0.28s cubic-bezier(0.4, 0, 0.2, 1);
//    vertical-align: middle;
//    cursor: pointer;
//
//    &:before {
//      content: '';
//      position: absolute;
//      top: -4px;
//      left: -4px;
//      width: 24px;
//      height: 24px;
//      background: #fafafa;
//      box-shadow: 0 2px 8px rgba(0,0,0,0.28);
//      border-radius: 50%;
//      transition: left 0.28s cubic-bezier(0.4, 0, 0.2, 1), background 0.28s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
//    }
//  }
//
//  &:not(.disabled) {
//    .ts-helper {
//      &:active {
//        &:before {
//          box-shadow: 0 2px 8px rgba(0,0,0,0.28), 0 0 0 20px rgba(128,128,128,0.1);
//        }
//      }
//    }
//  }
//
//  input {
//    position: absolute;
//    z-index: 1;
//    width: 46px;
//    margin: 0 0 0 -4px;
//    height: 24px;
//    opacity: 0;
//    cursor: pointer;
//
//    &:checked {
//      & + .ts-helper {
//        &:before {
//          left: 20px;
//        }
//      }
//    }
//  }
//
//  &:not([data-ts-color]){
//    @include ts-color($m-teal);
//  }
//
//  &.disabled {
//    opacity: 0.6;
//  }
//
//  &[data-ts-color="red"] {
//    @include ts-color($m-red);
//  }
//
//  &[data-ts-color="blue"] {
//    @include ts-color($m-blue);
//  }
//
//  &[data-ts-color="amber"] {
//    @include ts-color($m-amber);
//  }
//
//  &[data-ts-color="purple"] {
//    @include ts-color($m-purple);
//  }
//
//  &[data-ts-color="pink"] {
//    @include ts-color($m-pink);
//  }
//
//  &[data-ts-color="lime"] {
//    @include ts-color($m-lime);
//  }
//
//  &[data-ts-color="cyan"] {
//    @include ts-color($m-cyan);
//  }
//
//  &[data-ts-color="green"] {
//    @include ts-color($m-green);
//  }
//
//}

//tag-input {
//  tag {
//    padding-top: 0 !important;
//    padding-left: 10px !important;
//    padding-right: 10px !important;
//    margin-top: 4px !important;
//    margin-right: 10px !important;
//    height: 26px !important;
//    line-height: 28px !important;
//    letter-spacing: 0 !important;
//
//    delete-icon {
//      height: 16px !important;
//
//      svg {
//        position: relative;
//        top: -7px;
//        height: 16px !important;
//      }
//    }
//  }
//
//  .ng2-tag-input {
//    @extend .form-control;
//    height: auto;
//    border-bottom: 1px solid $input-border !important;
//    padding: 0 !important;
//
//    &.ng2-tag-input--focused {
//      border-bottom: 1px solid $input-border-focus !important;
//    }
//
//    input[type=text] {
//      padding: 0 !important;
//      height: 34px !important;
//    }
//  }
//}

//ng2-dropdown-menu {
//  .ng2-dropdown-menu {
//    padding: 10px 0 !important;
//    margin-left: 6px;
//  }
//
//  .ng2-menu-item {
//    font-size: 16px !important;
//    padding: 10px 20px !important;
//    line-height: 1.42857 !important;
//    height: auto !important;
//  }
//}

/*
 * IE 9 Placeholder
 */
//.ie9-placeholder {
//  color: #888 !important;
//  font-weight: normal;
//}
